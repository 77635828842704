@font-face {
    font-family: 'Mukta-Regular';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url('https://fonts.googleapis.com/css2?family=Mukta:wght@400&display=swap');
}

@font-face {
    font-family: 'Mukta-Medium';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url('https://fonts.googleapis.com/css2?family=Mukta:wght@500&display=swap');
}

@font-face {
    font-family: 'Mukta-Semibold';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url('https://fonts.googleapis.com/css2?family=Mukta:wght@600&display=swap');
}

@font-face {
    font-family: 'Mukta-Bold';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url('https://fonts.googleapis.com/css2?family=Mukta:wght@700&display=swap');
}

@font-face {
    font-family: 'icomoon';
    src: local('icomoon'), url('assets/fonts/icomoon.ttf') format('truetype');
}

/**
 * Building on the RNWeb reset:
 * https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/StyleSheet/initialRules.js
 */
html,
body,
#root {
    width: 100%;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    /* Allows content to fill the viewport and go beyond the bottom */
    min-height: 100%;
}
#root {
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    flex: 1;
}
html {
    scroll-behavior: smooth;
    /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
    -webkit-text-size-adjust: 100%;
    height: 100%;
}
body {
    display: flex;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
button:-webkit-autofill,
button:-webkit-autofill:hover,
button:-webkit-autofill:focus,
button:-webkit-autofill:active {
    -webkit-background-clip: text;
    background-clip: text;
}
